.calendar .monto{
    margin: 0;
    border-radius: 5px;
    padding-block: 3px;
    padding-inline: 10px;
    margin-bottom: 5px;
}

.calendar .dias{
    font-size: 14px;
}

.calendar .dias th{
    color: black;
    font-weight: 600;
}

.calendar table{
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    position: relative;
}

.calendar th{
    position: sticky;
    top: 0;
    background-color: white;
}



.calendar table thead tr th:first-child{
    border-top-left-radius: 5px;
} 

.calendar table thead tr th:last-child{
    border-top-right-radius: 5px;
}

.calendar table tbody tr:last-child td:first-child{
    border-bottom-left-radius: 5px;
}

.calendar table tbody tr:last-child td:last-child{
    border-bottom-right-radius: 5px;
}

.calendar table tbody tr:last-child th:first-child{
    border-bottom-left-radius: 5px;
}

.calendar table tbody tr:last-child th:last-child{
    border-bottom-right-radius: 5px;
}
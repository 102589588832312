.btnInicio{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    width: 100%;
}

.btnInicio:hover{
    color: inherit;
}

.btnInicio:active{
    color: black !important;
}

.contenedorCorte{
    height: 160px;
}
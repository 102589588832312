.pro-sidebar .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: none;
    backdrop-filter: blur(2px);
}

.sidebarContentBg{
    background: rgb(0,133,147);
    background: linear-gradient(135deg, rgba(0,133,147,1) 0%, rgba(101,78,124,1) 70%);
}

/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    .app .pro-sidebar{
        height: 100vh !important;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 

}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
 
}
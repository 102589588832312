@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");

@font-face {
    font-family: prueba;
    src: url('/public/fonts/Comfortaa-VariableFont_wght.ttf');
}

body {
    overscroll-behavior-y: contain;
}

.app {
    display: flex;
}

.app main {
    padding: 0px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.contenedorPrincipal{
    overflow-y: auto;
    padding-block: 5px;
    padding-inline: 5px;
}

.fondoLogin{
    background: rgb(0,133,147);
    background: linear-gradient(135deg, rgba(0,133,147,1) 0%, rgba(101,78,124,1) 50%);
}

.elemento{
    background-color: white;
    height: fit-content;
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.inputGroup{
    background: rgb(0,133,147);
    background: linear-gradient(135deg, rgba(101,78,124,1) 0%, rgba(0,133,147,1) 99%);
    border-radius: 5px;
    text-align: start;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.inputGroup label{
    padding-left: 10px;
    padding-top: 5px;
    font-size: 10px;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.inputGroup .form-control{
    font-size: 14px !important;
    padding-right: 0px;
}

.btnAplicar{
    background: rgb(0,133,147);
    background: linear-gradient(135deg, rgba(135, 111, 159, 1) 0%, rgba(101,78,124,1) 50%);
    color: white;
    border: none;
    padding-top: 0px;
    height: 100%;
    min-height: 30px;
    border-radius: 5px;
}

.inputUnderline{
    background-color: transparent;
    border: none;
    padding-top: 0px;
}

.inputUnderline:focus{
    background-color: transparent !important;
    box-shadow: none !important;
}

.boxShadow{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.aBtn{
    height: 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 17px;
}

.aBtn:hover{
    background-color: inherit;
    color: inherit;
}

.btnAgregar{
    width: fit-content;
    height: 45px;
    padding: 0;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 6px;
}

.rowBtnAgregar{
    height: 70px;
    position: absolute;
    bottom: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.modal-content{
    border: none !important;
}

.modal-header{
    display: flex;
    justify-content: center;
    border: none;
}

.modal-footer{
    border: none;
    display: flex;
    justify-content: center;
}

.btnRadius{
    border-radius: 25px;
    font-size: 16px;
}

.btnEditar:focus, .btnEditar:hover, .btnBorrar:focus, .btnBorrar:hover{
    background-color: inherit;
    color: inherit;
}

.modal-content textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="file"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: #d9d9d9;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.139);
  outline: 0 none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.fondoLogin input:-webkit-autofill{
    -webkit-text-fill-color: white !important;
}

select:focus{
    border-color: #000000 !important;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.139) !important;
    outline: 0 none;
}

select option {
    color: #000;
}

.modalCargando .modal-content{
    background-color: transparent;
    border: none;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.show {
    opacity: 1;
    backdrop-filter: blur(4px);
}

.degradado{
    background: rgb(0,133,147);
    background: linear-gradient(135deg, rgba(0,133,147,1) 0%, rgba(101,78,124,1) 80%);
}
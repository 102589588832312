.navbar{
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.offcanvas-backdrop{
    background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas-backdrop.show {
    opacity: 1;
    backdrop-filter: blur(4px);
  }
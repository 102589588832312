

table {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 100%;
    font-size: 11px;
}

thead tr{
    text-align: center;
}

tr{
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}
  
th {
    background-color: #654E7C;
    color: white;
}

.thSubheader{
    background-color: #B49ACD;
    padding-block: 5px;
    color: white;
    font-size: 14px;
}

th, td {
    white-space: nowrap;
    padding-block: 15px;
    padding-inline: 10px;
}

.tdConcepto{
    padding-block: 5px;
    min-width: 160px !important;
}

.tdConcepto .input-group .input-group-prepend .input-group-text{
    background-color: transparent;
    padding: 0;
    height: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 100%;
}

.tdConcepto .input-group .form-control{
    background-color: transparent;
    padding: 0;
    height: 100%;
    font-size: inherit;
    border: none;
}

.thHeader{
    font-size: 15px;
}

th:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

th:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

td:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

td:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.tablaAnual table{
    width: 100%;
    border-collapse: collapse;
    position: relative;
}

.sticky{
    position: sticky;
    left: 0;
    background-color: white;
}

.stickyTH{
    position: sticky;
    left: 0;
    background-color: #654E7C;
}